import { CDLTypes, DataLayer, SessionHint } from 'mmt-cdl';

import { VehiclesResponseItem } from 'types/vehiclesResponse.models';

export const pullDataIntoCDL = (vehicle: VehiclesResponseItem, domain: string, raqDetails?: Record<string, string>) => {

  const raqDataLayer = new DataLayer({ pod: raqDetails ? 'raq': 'pes', site: domain === 'toyota' ? 'tcom' : 'bat' });

  const hintParams: SessionHint = {
    series: vehicle.series,
    seriesYear: vehicle.year,
    trimCode: vehicle.model,
    trim: vehicle.grade,
    monthlyPayment: vehicle.payment.toString(),
    downPayment: vehicle.downPayment.toString(),
    creditScore: vehicle.tier,
    paymentOption: vehicle.purchaseType,
    term: vehicle.term.toString(),
    tradeInValue: '0',
    offerId: vehicle.offers.length > 0 ? vehicle.offers[0].offerId : '',
  };

  raqDataLayer.hint(hintParams);

  if (raqDetails) {
    const sessionHintParams: CDLTypes.RAQProps = {
      contactPreference: raqDetails.preference as 'text' | 'email' | 'phone',
      email: raqDetails.email,
      firstName: raqDetails.firstname,
      lastName: raqDetails.lastname,
      phone: raqDetails.phone,
    };

    raqDataLayer.sessionHint(sessionHintParams);
  }
};
