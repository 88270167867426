import React, { HTMLAttributes, useMemo } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import Content from "./Content";
import { VehiclesResponseItem } from "../../../../types/vehiclesResponse.models";
import { AppStoreContext } from "../../../../stores/AppStore";
import getGlobalBlock from "../../../../utils/Analytics/blocks/getGlobalBlock";
import getPageBlock from "../../../../utils/Analytics/blocks/getPageBlock";
import { getOfferBlockFromResponse } from "../../../../utils/Analytics/blocks/getOfferBlock";
import Button from "../../../../components/Button";
import { showPeLink, getPeLink } from "../../../../utils/peLinkUtil";
import AALinkAnchor from "../../../../utils/Analytics/AALinkAnchor";
import AALinkRouterAnchor from "../../../../utils/Analytics/AALinkRouterAnchor";
import { pullDataIntoCDL } from "../../../../utils/pullDataIntoCDL";
import styles from "./TrimItem.module.scss";

interface TrimOptionProps extends React.InputHTMLAttributes<HTMLInputElement> {
  contentSection: string;
  trimOption: VehiclesResponseItem;
  almostInBudget?: boolean;
  onClick: () => void;
}

const TrimOptionItem = ({
  trimOption,
  almostInBudget = false,
  onClick,
  contentSection,
}: TrimOptionProps) => {
  const {
    AppStore: { setSelectedTrim, tdaCode, zip, language, filters, domain },
  } = React.useContext(AppStoreContext);

  const { t } = useTranslation();

  const taggingBlocks = useMemo(
    () => ({
      ...getGlobalBlock(),
      ...getPageBlock(contentSection),
      ...getOfferBlockFromResponse(trimOption),
    }),
    [contentSection, trimOption]
  );

  const peLink = showPeLink(almostInBudget, trimOption)
    ? getPeLink(tdaCode, zip, trimOption, language, domain)
    : undefined;

  const handleOnClick = () => {
    setSelectedTrim(trimOption);
    onClick();
  };

  const RenderWrapper = ({
    children,
    className,
  }: HTMLAttributes<HTMLElement>) =>
    peLink ? (
      <AALinkAnchor
        href={peLink}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </AALinkAnchor>
    ) : (
      <AALinkRouterAnchor
        to={`/vehicleDetails/${trimOption.series}/${trimOption.model}/${trimOption.purchaseType}?zip=${zip}/?lang=${language}/?filters=${filters}`}
        className={className}
        onClick={() => pullDataIntoCDL(trimOption, domain)}
      >
        {children}
      </AALinkRouterAnchor>
    );
  return (
    <RenderWrapper className={styles.linkWrapper}>
      <Button
        fullWidth
        variant="default"
        className={styles.trimCta}
        onClick={() => handleOnClick()}
        getBlocks={() => taggingBlocks}
        linkText={
          peLink
            ? t("vehicleCard.estimatePayment")
            : t("budgetOptions.offerDetails")
        }
        buttonAction="Open Modal"
      >
        <Content
          trim={trimOption}
          almostInBudget={almostInBudget}
          isPeLink={peLink !== undefined}
        />
      </Button>
    </RenderWrapper>
  );
};

export default observer(TrimOptionItem);
